import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { forwardRef, useState, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Backdrop, Fade, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, Button } from '@mui/material';
import { UpdateNote } from "src/plugins/redux/reducers/UsersReducers";
import {
  addFavorite,
  deleteFavorite,
  fetchFavorites,
  fetchItem,
  fetchRfpProducts,
  selectCurrentItem,
  selectFavorites,
  selectRfpProducts,
  selectStatus,
  selectPagination
} from "src/plugins/redux/reducers/RFPsReducer";
import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import Papa from "papaparse";
import { useForm } from "react-hook-form";
import ProductsFilterModal from "./productFiltersModal";
import toast from "react-hot-toast";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { currentUserSelector } from "plugins/redux/reducers/AuthReducer";
import formatDate from "src/componenets/DateFormat";


const ProductsShortList = forwardRef((props: any, ref: any): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const fetchedProducts = useSelector(selectRfpProducts);
  const status = useSelector(selectStatus);
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState({});
  const [sortBy, setSortBy] = useState();
  const [sortType, setSortType] = useState("ASC");
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const rfp = useSelector(selectCurrentItem);
  const favorites = useSelector(selectFavorites);
  const [columnToSort, setColumnToSort] = useState("supplier");
  const currentUser = useSelector(currentUserSelector);
  const [supplier, setSupplier] = useState();
  const [productinfo, setproductinfo] = useState([]);
  const [showSuccessModal2, setShowSuccessModal2] = useState<boolean | null>(false);
  const { suppliers, producers, categories } = useSelector((state) => state.rfps);
  const { currentPage, totalPages } = useSelector(selectPagination);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setProducts(fetchedProducts);
    setFilteredProducts(fetchedProducts);
  }, [fetchedProducts])

  function convertSize(size, price) {
    const ozRegex = /(\d+(\.\d+)?) oz/;
    const mlRegex = /(\d+(\.\d+)?) ml/;
    const literRegex = /(\d+(\.\d+)?) L/;
    const GRegex = /(\d+(\.\d+)?) G/;
    const kegRegex = /Keg - (1\/2|1\/4|1\/6|Euro Keg) Barrel/;

    const ozMatch = size?.match(ozRegex);
    const mlMatch = size?.match(mlRegex);
    const literMatch = size?.match(literRegex);
    const kegMatch = size?.match(kegRegex);
    const GMatch = size?.match(GRegex);

    if (ozMatch) {
      const prices = price / parseFloat(ozMatch[1]);
      return `${prices.toFixed(2)} $`;
    } else if (mlMatch) {
      const mlValue = parseFloat(mlMatch[1]);
      const ozValue = mlValue / 29.5741324921;
      const prices = price / ozValue;
      return `${prices.toFixed(2)} $`;
    } else if (literMatch) {
      const literValue = parseFloat(literMatch[1]);
      const ozValue = literValue * 33.814;
      const prices = price / ozValue;
      return `${prices.toFixed(2)} $`;
    } else if (GMatch) {
      const GValue = parseFloat(GMatch[1]);
      const ozValue = GValue * 128;
      const prices = price / ozValue;
      return `${prices.toFixed(2)} $`;
    } else if (kegMatch) {
      const kegSize = kegMatch[1];
      let kegPrices;
      if (kegSize === "1/2") {
        kegPrices = 1984;
      } else if (kegSize === "1/4") {
        kegPrices = 992;
      } else if (kegSize === "1/6") {
        kegPrices = 661;
      } else if (kegSize === "Euro Keg") {
        kegPrices = 1690;
      }
      if (kegPrices !== undefined) {
        const prices = price / kegPrices;
        return `${prices.toFixed(2)} $`;
      }
    }

    return "-";
  }
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, dirtyFields },
    getValues,
    control,
    setError,
    setValue,
  } = useForm({});

  const [refreshProducts, setRefreshProducts] = useState(false);

  const tableRef = useRef();
  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const filtersString = JSON.stringify(filters);
        const { scrollTop, scrollHeight, clientHeight } = tableRef.current;

        if ((scrollTop + clientHeight >= scrollHeight - 1 && status !== 'loading') && totalPages >= (parseInt(currentPage) + 1)) {
          dispatch(
            fetchRfpProducts({
              rfp_id: id,
              page: parseInt(currentPage) + 1 ?? 1,
              limit: 100,
              filters: filtersString,
              sortColumn: sortBy ?? 'supplier',
              sortOrder: sortType,
            })
          );
        }
      }
    };

    const currentTable = tableRef.current;

    if (currentTable) {
      currentTable.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentTable) {
        currentTable.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentPage, id, status, dispatch, totalPages]);

  useEffect(() => {
    dispatch(
      fetchItem({
        id: id,
      })
    );
    if (currentUser?.role_id == 2) {
      dispatch(
        setHeaderActionBtns([
          {
            link: "/create",
            text: "Add New RFP",
          },
          {
            link: `/rfp/products/favorites-list/${id}`,
            text: "Favorites",
          }, ,

        ])
      );
    } else if (currentUser?.role_id == 4) {
      dispatch(
        setHeaderActionBtns([
          {
            link: `/rfp/products/favorites-list/${id}`,
            text: "Favorites",
          }
        ])
      );
    } else {
      dispatch(setHeaderActionBtns([]));
    }
    dispatch(setHeaderArrowBack(false));
    dispatch(setShowSearch(false));
    dispatch(fetchFavorites(id));
  }, []);

  useEffect(() => {
    dispatch(fetchRfpProducts({ rfp_id: id, page: 1, limit: 100, filters: filters, sortColumn: "supplier",sortOrder: "ASC" }));
  }, [])


  useEffect(() => {
    if (rfp.rfp_title) {
      dispatch(setHeaderTitle(`${rfp?.rfp_title}`));
    }
  }, [rfp]);

  const extractNumericalValue = (str) => {
    if (str) {
      const numericalValue = parseFloat(str.match(/\d+(\.\d+)?/));
      return isNaN(numericalValue) ? 0 : numericalValue;
    } else {
      return 0;
    }
  };

  const handleTypeSort = (columnName: string, numeric: boolean = false) => {
    const newSortType = sortType === "ASC" ? "DESC" : "ASC";
    setColumnToSort(columnName);
  
    setSortBy(columnName);
    setSortType(newSortType);
  
    // Dispatch fetchRfpProducts with sorting parameters
    dispatch(
      fetchRfpProducts({
        rfp_id: id,
        page: 1,
        limit: 100,
        filters: filters,
        sortColumn: columnName,
        sortOrder: newSortType,
      })
    );
  };
  

  const exportToCsv = () => {
    var productsLocal = [];
    products.map((product) => {
      let unitSizes = "";

      if (product.productSizeInfo && product.productSizeInfo.length > 0) {
        unitSizes = product.productSizeInfo
          .filter(size => size?.name != null && size?.name !== '')
          .map(size => size.name)
          .join(", ");
      }

      const product_sell_sheets = product.assets?.find((asset) => asset.assets_type == 'sell_sheets')?.asset_file;
      const image_front = product.assets?.find((asset) => asset.assets_type == 'product_image_front')?.asset_file;
      const image_back = product.assets?.find((asset) => asset.assets_type == 'product_image_back')?.asset_file;
      const label_front = product.assets?.find((asset) => asset.assets_type == 'label_image_front')?.asset_file;
      const label_back = product.assets?.find((asset) => asset.assets_type == 'label_image_back')?.asset_file;
      const recipes = product.recipes?.map((recipe) => { return recipe.asset_file })?.join(',');
      const logos = product.assets?.find((asset) => asset.assets_type == 'logos')?.asset_file;

      productsLocal.push({
        Category: product.catinfo ? product.catinfo.name : "",
        "Product Name": product.name ? product.name : "",
        "Product Description": product.description ? product.description : '',
        Supplier: product.supplierInfo ? product.supplierInfo.name : "",
        Producer: product.producerInfo ? product.producerInfo.name : "",
        Type: product.categoryTypeInfo ? product.categoryTypeInfo.name : "",
        "Varietal/Sup-Type": product.categorySubTypeInfo
          ? product.categorySubTypeInfo.name
          : "",
        Placement: product.itemPourInfo ? product.itemPourInfo.name : "",
        'ABV %': product.abv ? product.abv : "",
        "Prominent Flavors": product.prominentFlavorsInfo ? product.prominentFlavorsInfo?.map((flavor) => { if (flavor?.name) { return flavor.name } }).join(',') : "",
        "Country of Origin": product.countryOfOriginInfo ? product.countryOfOriginInfo?.name : "",
        "Region / Appellation": product.regionsInfo ? product.regionsInfo?.name : "",
        "Unit Size": product.productSizeInfo ? product.productSizeInfo[0]?.name : "",
        "Item Closure": product.itemClosureInfo
          ? product.itemClosureInfo.name
          : "",
        "National Average Unit Price - Case 1": product.national_pricing ? product.national_pricing : "",
        "Price/Ounce": product.price_per_ounce ? product?.price_per_ounce : "",
        "Units per Case": product.units_per_case ? product?.units_per_case : "",
        "Other Available Sizes": unitSizes ? unitSizes : "",
        "Product Sell Sheet": product_sell_sheets ? product_sell_sheets : '',
        "Product Image (front)": image_front ? image_front : '',
        "Product Image (back)": image_back ? image_back : '',
        "Label Image (front)": label_front ? label_front : '',
        "Label Image (back)": label_back ? label_back : '',
        "Recipes": recipes ? recipes : '',
        "Logos": logos ? logos : '',
        "Video Links": product.videos ? product.videos : '',
        "Awards / Accolades": product.awards ? product.awards : '',
        "Notes, if any": product.notes ? product.notes : '',
      });
    });
    var csv = Papa.unparse(productsLocal);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = window.URL.createObjectURL(csvData);

    var testLink = document.createElement("a");
    testLink.href = csvURL;
    testLink.setAttribute("test", "test.csv");
    testLink.click();
  };

  const onSubmit = async (data: any) => {
    data.product_id = productinfo.id;
    const filtersString = JSON.stringify(filters);
    const response = await dispatch(UpdateNote(data));
    if (response.payload.success) {
      toast.success("Note Updated!");
      dispatch(fetchRfpProducts({ rfp_id: id, page: "1", limit: 100, filters: filtersString, sortColumn: "supplier",sortOrder: "ASC" }));

    } else {
      toast.error(response.payload);
    }

  };

  const handleFilterProducts = (filters: any) => {
    const filtersString = JSON.stringify(filters);
    setFilters(filtersString);
    dispatch(fetchRfpProducts({ rfp_id: id, page: "1", limit: 100, filters: filtersString, sortColumn: "supplier",sortOrder: "ASC"}));
    setLoading(false);
    setShowFiltersModal(false);
  };



  const handleClick = async (product_id: any, rfp_id: any) => {

    const response = await dispatch(
      addFavorite({
        product_id: product_id,
        rfp_id: rfp_id,
      })
    );
    if (response.payload.success === "Add.") {
      dispatch(fetchFavorites(id));
      toast.success("Product added to Favorites");
    } else {
      toast.error("Product is already in Favorites");
    }
  };

  const isFavorite = (productId: any) => {
    const is = favorites.some(
      (favorite: any) => favorite.product_id === productId
    );
    return is;
  };

  const handleDeleteFav = async (Id: any) => {
    try {
      const uid = favorites.find((fav: any) => fav.product_id === Id);
      const response = await dispatch(deleteFavorite(uid?.id));
      if (response.payload.success) {
        dispatch(fetchFavorites(id));
        toast.success("Product is deleted from Favorites");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const clearAll = () => {
    reset({ 'supplier_id': '', 'category_id': '', 'producer_id': '', 'category_type_id': '', 'sizes': '', 'category_sub_type_id': '', 'item_closure_id': '', 'pour_id': '' });
    setProducts(fetchedProducts);
  }


  return (
    <div>
      <form onSubmit={handleSubmit((data) => handleFilterProducts(data))}>
        <ProductsFilterModal
          products={products}
          rfpSuppliers={suppliers}
          rfpProducers={producers}
          rfpCategories={categories}
          showFiltersModal={showFiltersModal}
          setShowFiltersModal={setShowFiltersModal}
          register={register}
          control={control}
          clearAll={clearAll}
          onSubmit={handleSubmit((data) => handleFilterProducts(data))}
          supplier={supplier}
          setSupplier={setSupplier}
        />
      </form>

      <div className="d-flex align-items-center justify-content-between m-3">
        <button
          className="btn btn-outline-custom-primary btn-pill d-flex align-items-center justify-conten-center gap-1"
          onClick={() => setShowFiltersModal(true)}
        >
          All Filters
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.99969 2H12.9997L7.98969 8.3L2.99969 2ZM0.24969 1.61C2.26969 4.2 5.99969 9 5.99969 9V15C5.99969 15.55 6.44969 16 6.99969 16H8.99969C9.54969 16 9.99969 15.55 9.99969 15V9C9.99969 9 13.7197 4.2 15.7397 1.61C16.2497 0.95 15.7797 0 14.9497 0H1.03969C0.20969 0 -0.26031 0.95 0.24969 1.61Z" fill="#6A7682" />
          </svg>
        </button>
        <button
          type="button"
          className="btn btn-pill btn-outline-custom-primary font-weight-bold"
          onClick={() => exportToCsv()}
        >
          Export CSV
          <i className="la la-upload"></i>
        </button>
      </div>
      <div className="d-flex justify-content-end m-3">

      </div>
      <div
        ref={tableRef}
        style={{ "max-height": "70vh", "overflow-y": "auto", minWidth: '1290px', 'border-top-left-radius': '3rem', 'border-top-right-radius': '3rem' }}
        className="custom-scroll"
      >
        <table className="table submissions-table rounded-top-pill table-striped table-lg text-center justify-content-center" style={{ 'overflow': 'auto !important', 'min-width': 'max-content' }}>
          <thead class="bg-custom-secondary">
            <tr className="text-white">
              <th
                style={{ minWidth: "125px", cursor: "pointer", 'border-top-left-radius': '2rem' }}
                onClick={() => handleTypeSort("supplier")}
              >
                Supplier
                {columnToSort === 'supplier' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th
                onClick={() => handleTypeSort("name")}
                style={{ minWidth: "125px", cursor: "pointer", width: "120px" }}
              >
                Product Name
                {columnToSort === 'name' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th
                onClick={() => handleTypeSort("category")}
                style={{ cursor: "pointer", width: "100px" }}
              >
                Category
                {columnToSort === 'category' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th
                onClick={() => handleTypeSort("categoryType")}
                style={{ cursor: "pointer", width: "90px" }}
              >
                Type
                {columnToSort === 'categoryType' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th
                onClick={() => handleTypeSort("categorySubType")}
                style={{ minWidth: "140px", cursor: "pointer", width: "90px" }}
              >
                Varietal/Sub-Type
                {columnToSort === "categorySubType" &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th
                onClick={() => handleTypeSort("itemPour")}
                style={{ cursor: "pointer" }}
              >
                Placement
                {columnToSort === "itemPour" &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th onClick={() => handleTypeSort("productSize", true)} style={{ cursor: "pointer" }}>
                Unit Size
                {columnToSort === 'productSize' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th onClick={() => handleTypeSort("abv", true)} style={{ cursor: "pointer" }}>
                ABV
                {columnToSort === 'abv' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th onClick={() => handleTypeSort("national_pricing", true)} style={{ cursor: "pointer" }}>
                Unit Price
                {columnToSort === 'national_pricing' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th onClick={() => handleTypeSort('price_per_ounce', true)} style={{ cursor: "pointer" }}>
                Price/Ounce
                {columnToSort === 'price_per_ounce' &&
                  (sortType == "ASC" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </th>
              <th>Last Updated</th>
              <th style={{ 'border-top-right-radius': '2rem' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts?.map((product: any, index: number) => (
              <tr>
                <td>
                  {product.supplierInfo ? product.supplierInfo.name : "-"}
                </td>
                <td>{product.name}</td>
                <td>{product.catinfo ? product.catinfo.name : "-"}</td>
                <td>
                  {product.categoryTypeInfo
                    ? product.categoryTypeInfo.name
                    : "-"}
                </td>
                <td>
                  {product.categorySubTypeInfo
                    ? product.categorySubTypeInfo.name
                    : "-"}
                </td>
                <td>
                  {product.itemPourInfo ? product.itemPourInfo.name : "-"}
                </td>
                <td>
                  {product.productSizeInfo
                    ? product.productSizeInfo[0]?.name
                    : "-"}
                </td>
                <td>{product.abv ? `${product.abv} %` : "-"}</td>
                <td>
                  {product.national_pricing ? `$${product.national_pricing}` : "-"}
                </td>
                <td>
                  {product.price_per_ounce ? `$${product.price_per_ounce}` : "-"}
                </td>
                <td>{product.updated_at ? formatDate(product.updated_at) : '-'}</td>
                <td className="d-flex align-items-center">
                  <div
                    className="mr-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setproductinfo(product);
                      setValue('rfp_notes', product.rfp_notes);
                      setShowSuccessModal2(true);
                    }
                    }
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#003ACE" height="20" width="20" version="1.1" id="Capa_1" viewBox="0 0 490 490" xml:space="preserve">
                      <g>
                        <path d="M372.816,47.832h-63.743C302.403,35.873,285.158,0,285.158,0h-80.316c0,0-17.245,35.873-23.915,47.832h-63.743   c-21.309,0-38.645,17.336-38.645,38.645V451.36c0,21.304,17.336,38.64,38.645,38.64h255.632c21.309,0,38.645-17.336,38.645-38.64   V86.477C411.461,65.168,394.125,47.832,372.816,47.832z M217.847,20.417h54.306c10.962,23.148,21.155,42.045,30.421,56.386   c-35.958,16.767-79.189,16.767-115.148,0C196.692,62.462,206.886,43.565,217.847,20.417z M391.044,451.36   c0,10.048-8.179,18.223-18.228,18.223H117.184c-10.049,0-18.228-8.175-18.228-18.223V86.477c0-10.049,8.18-18.228,18.228-18.228   h51.431c-1.494,2.251-11.086,15.532-11.086,15.532s36.684,26.218,87.474,26.218c50.963,0,87.468-26.218,87.468-26.218   S322.88,70.5,321.385,68.249h51.431c10.049,0,18.228,8.179,18.228,18.228V451.36z" />
                        <path d="M128.703,439.223h232.594V135.983H128.703V439.223z M149.12,156.4h191.76v262.406H149.12V156.4z" />
                        <rect x="173.454" y="198.026" width="143.092" height="20.417" />
                        <rect x="173.454" y="277.395" width="143.092" height="20.417" />
                        <rect x="173.454" y="356.769" width="143.092" height="20.417" />
                      </g>
                    </svg>
                  </div>
                  <a
                    target="_blank"
                    className="mr-4"
                    href={`/submission/${product.id}`}
                    state={{ product: product }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_4_3167)">
                        <path
                          d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z"
                          fill="#003ACE"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4_3167">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <div
                    onClick={() => {
                      !isFavorite(product.id)
                        ? handleClick(product.id, product.rfp_id)
                        : handleDeleteFav(product.id);
                    }}
                    style={{ cursor: "pointer", marginBottom: '2px' }}
                  >
                    {isFavorite(product.id) ? (
                      <FavoriteIcon sx={{ color: "#003ace" }} />
                    ) : (
                      <FavoriteBorderIcon sx={{ color: "#003ace" }} />
                    )}
                  </div>
                </td>
              </tr>
            ))}
            {status === "loading" && (
              <tr>
                <td colSpan="12" className="text-center">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="spinner-border text-custom-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {status === "error" && (
        <div
          className="d-flex flex-column gap-2 align-items-center justify-content-center"
          style={{ minHeight: "50vh" }}
        >
          <strong className="text-center fs-1 text-custom-primary">
            Error occurred while fetching items.
          </strong>
          <button
            className="btn btn-custom-primary btn-pill"
            onClick={() => dispatch(fetchRfpProducts({ rfp_id: id, page: currentPage ?? 1, limit: 100, filters: filtersString }) as any)}
          >
            <i className="fa fa-redo-alt" />
            Retry
          </button>
        </div>
      )}
      <Modal
        isOpen={showSuccessModal2}
        toggle={() => setShowSuccessModal2(!showSuccessModal2)}
      >
        <ModalHeader>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <p style={{
              textAlign: "center",
              fontSize: "13px",
              marginTop: "10px",
              marginBottom: "10px",
              width: "385px",
            }}>Submission Notes (not shared with Supplier)</p>
            <IconButton onClick={() => { setShowSuccessModal2(!showSuccessModal2) }}>
              <CloseIcon />
            </IconButton>
          </div>
        </ModalHeader>
        <ModalBody>
          <form className="col-12" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Submission Note"
              {...register('rfp_notes')}
              defaultValue={productinfo?.rfp_notes}
              InputProps={{ sx: { borderRadius: "25px" } }}
              fullWidth
              margin="normal"
              multiline
              rows={8}
            />
            <button type="submit" className="btn btn-custom-primary rounded-pill w-100">
              Save
            </button>
          </form>
        </ModalBody>
      </Modal>
    </div >
  );

});

export default ProductsShortList;
